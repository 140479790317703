import { makeEnvironmentProviders } from '@angular/core';
import { MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatRadioDefaultOptions } from '@angular/material/radio';
import { provideRmaIcon } from '@rma/generic/ui/icon';

const fieldDefaults: MatFormFieldDefaultOptions = {
  appearance: 'fill',
};

// This could be a provider below but then the entire mat checkbox js in added the vendor
export const RMA_MAT_CHECKBOX_DEFAULT_OPTIONS: MatCheckboxDefaultOptions = {
  color: 'primary',
};

// This could be a provider below but then the entire mat radio js in added the vendor
export const RMA_MAT_RADIO_DEFAULT_OPTIONS: MatRadioDefaultOptions = {
  color: 'primary',
};

// This could be a provider below but then the entire mat dialog js in added the vendor
export const RMA_MAT_DIALOG_DEFAULT_OPTIONS: MatDialogConfig = {
  maxWidth: '100vw',
};

export function provideRmaMaterial() {
  return makeEnvironmentProviders([
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: fieldDefaults,
    },
    provideRmaIcon(),
  ]);
}
